const videoId = "heading-video";
const placeholderId = "heading-video-placeholder";

function playVideoWhenReady (videoId, placeholderId) {
  const video = document.getElementById(videoId);
  const video_image = document.getElementById(placeholderId);
  
  if (!video && !video_image) return
  video.addEventListener("canplay", function () {
    const playing = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    if (!playing) {
      setTimeout(() => {
        video.classList.remove("hidden");
        video_image.classList.add("hidden");
      }, 5);
    }
  });
}

playVideoWhenReady(videoId, placeholderId);